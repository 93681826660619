@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : common.css
 Style : common
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP");
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 14px !important;
  line-height: 1.7;
  overflow-y: visible !important;
  overflow-x: hidden !important;
}

@media screen and (max-width: 640px) {
  html {
    font-size: 0.85714rem !important;
  }
}

body {
  font-weight: 400;
  letter-spacing: 0.6;
  color: #333333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.28571rem;
  word-wrap: break-word;
  background-position: left center;
  overflow-x: hidden;
  background: #fff;
  line-height: 1.7 !important;
}

@media screen and (max-width: 640px) {
  body {
    font-size: 0.85714rem !important;
  }
}

a {
  transition: 1s;
  color: #6dc2eb !important;
  text-decoration: none;
}

.inner {
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1120px) {
  .inner {
    width: 97%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    margin: 0 auto;
  }
}

#main, #main #col_main {
  width: 100% !important;
  max-width: 100% !important;
  float: none !important;
}

#contents_wrap {
  position: relative;
  z-index: 1;
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1120px) {
  #contents_wrap {
    width: 97%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  #contents_wrap {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1120px) {
  #contents_wrap {
    width: 100% !important;
    min-width: 100%;
  }
}

#contents {
  padding: 0;
  background: none !important;
  width: 100%;
}

@media screen and (max-width: 100%) {
  #main {
    width: 100%;
  }
}

@media screen and (max-width: 100%) {
  #main #col_main {
    width: 100%;
  }
}

@media screen and (max-width: 100%) {
  #side {
    width: 18.75000%;
  }
}

@media screen and (max-width: 100%) {
  #side aside {
    width: 100% !important;
  }
}

aside div#form_calendar {
  min-width: auto !important;
}

.blog #contents article .detail {
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .blog #contents {
    padding: 0;
  }
  .blog #contents article {
    padding: 30px 10px 10px;
  }
  .blog #contents article .detail {
    margin-top: 20px;
  }
}

.map {
  margin: 56px auto 120px;
  padding: 60px 0;
}

@media screen and (max-width: 834px) {
  .map {
    width: 90%;
  }
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

@media screen and (max-width: 834px) {
  .map li {
    text-align: center;
  }
}

.map li:last-child {
  display: none;
}

.map li a {
  color: #333 !important;
}

.policy h2 {
  border-bottom: 1px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 5px;
}

.policy p {
  margin: 0 0 50px;
}

table {
  table-layout: fixed;
}

table td {
  margin-bottom: -1px !important;
  padding: 20px !important;
}

.tlauto table {
  table-layout: auto;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

@media screen and (max-width: 834px) {
  .youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

u {
  text-decoration: underline;
}

.borB {
  border-bottom: 1px dashed #333;
}

.b {
  font-weight: bold;
}

b {
  font-weight: bold;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a:hover {
  opacity: 0.6;
  transition: 0.7s;
}

.tacenter {
  text-align: center !important;
}

.ColLeft {
  float: left;
}

.ColRight {
  float: right;
}

.flexleft {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
}

.flexcenter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.br {
  display: block;
}

.br_pc {
  display: block;
}

@media screen and (max-width: 640px) {
  .br_pc {
    display: inline;
  }
}

.br_sp {
  display: inline;
}

@media screen and (max-width: 640px) {
  .br_sp {
    display: block;
  }
}

.br_tab {
  display: inline;
}

@media screen and (max-width: 834px) {
  .br_tab {
    display: block;
  }
}

/********* sukuro-ruba- *********/
div::-webkit-scrollbar {
  width: 8px;
}

div::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #ccc;
}

div::-webkit-scrollbar-thumb {
  background: rgba(109, 194, 235, 0.25);
  border-radius: 10px;
  box-shadow: none;
}

/*********PANKUZU *********/
.pankuzu ol li {
  display: inline-block;
  font-size: 12px;
  position: relative;
}

.pankuzu ol li:nth-last-child(2), .pankuzu ol li:nth-last-child(3), .pankuzu ol li:nth-last-child(4) {
  margin-right: 30px;
}

.pankuzu ol li:nth-last-child(2):after, .pankuzu ol li:nth-last-child(3):after, .pankuzu ol li:nth-last-child(4):after {
  content: ">";
  position: absolute;
  right: -20px;
}

.pankuzu ol li a {
  color: #333 !important;
  text-decoration: underline;
}

.pankuzu a {
  color: #333 !important;
  text-decoration: underline;
}

/*----------------- FAQ -----------------*/
.faq_q {
  font-size: 1.14286rem;
  border-bottom: 1px #6dc2eb dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #6dc2eb;
  font-weight: bold;
}

.faq_q:before {
  content: "Q";
  display: block;
  background: #6dc2eb;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -9px;
}

.faq_a {
  font-size: 1.14286rem;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #ccc solid;
  padding-bottom: 30px !important;
}

.faq_a:before {
  content: "A";
  display: block;
  background: #6dc2eb;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
}

.dnone {
  display: none;
}

@media screen and (max-width: 834px) {
  .dnone_sp {
    display: none;
  }
}

.dnone_pc {
  display: none;
}

@media screen and (max-width: 640px) {
  .dnone_pc {
    display: block;
  }
}

.container_f {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .container_f {
    flex-flow: column;
  }
}

.container_f .box {
  width: 49%;
}

.container_f .box img {
  width: 100% !important;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

@media screen and (max-width: 640px) {
  .container_f .box {
    width: 100%;
  }
}

.container_3 .inner {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .container_3 .inner {
    flex-flow: column;
  }
}

.container_3 .inner .box {
  width: 30%;
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .container_3 .inner .box {
    width: 100%;
  }
}

.container_3 .inner:after {
  content: "";
  display: block;
  width: 30%;
}

.container_4 .inner {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .container_4 .inner {
    flex-flow: column;
  }
}

.container_4 .inner .box {
  flex: 0 0 25%;
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .container_4 .inner .box {
    width: 100%;
  }
}

.container_4 .inner:after {
  content: "";
  display: block;
  width: 30%;
}

.container_4 .inner:before {
  content: "";
  display: block;
  width: 30%;
  order: 1;
}

.txtimg01 table td {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .txtimg01 table td {
    display: block;
  }
}

.txtimg01 table td:nth-child(odd) {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .txtimg01 table td:nth-child(odd) {
    width: 100%;
  }
}

.txtimg02 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .txtimg02 .inner {
    flex-flow: column;
  }
}

.txtimg02 .txt {
  width: 49%;
}

@media screen and (max-width: 640px) {
  .txtimg02 .txt {
    width: 100%;
  }
}

.txtimg02 .img {
  width: 49%;
}

@media screen and (max-width: 640px) {
  .txtimg02 .img {
    width: 100%;
  }
}

.txtimg03 .txt {
  background: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 30px !important;
}

.txtimg03 tbody {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.txtimg03 tr:nth-child(2) {
  display: block;
  width: 80%;
  transform: translateY(-25%);
}

.txtimg04 tr {
  display: flex;
}

@media screen and (max-width: 640px) {
  .txtimg04 tr {
    flex-flow: column;
  }
}

.txtimg04 td {
  width: 60%;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
}

.txtimg04 .txt {
  background: #fff;
  margin-left: -10%;
  transform: translateY(20%);
  padding: 40px !important;
}

@media screen and (max-width: 640px) {
  .txtimg04 .txt {
    transform: none;
    margin: 0;
  }
}

/* table td padding */
.tdpd0 td {
  padding: 0 !important;
}

.tdpd05 td {
  padding: 5px !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.tdpd15 td {
  padding: 15px !important;
}

.tdpd20 td {
  padding: 20px !important;
}

.tdpd25 td {
  padding: 25px !important;
}

.tdpd30 td {
  padding: 30px !important;
}

.tdpd35 td {
  padding: 35px !important;
}

.tdpd40 td {
  padding: 40px !important;
}

.tdpd45 td {
  padding: 45px !important;
}

.tdpd50 td {
  padding: 50px !important;
}

td.pdT10 {
  padding-top: 10px !important;
}

/* padding */
.pd0 {
  padding: 0px !important;
}

.pd10 {
  padding: 10px !important;
}

.center p, .center a, .center h1, .center h2, .center h3, .center h4, .center h5, .center div, .center span {
  text-align: center;
}

/********* contact *********/
.contact_txt a {
  color: #333 !important;
  text-decoration: underline;
}

/********* Google Map &youtube *********/
/* small.css */
/* googlemap for single.php */
iframe[src^="https://www.google.com/maps/"] {
  width: 100%;
  height: 100%;
}

.ggmap {
  position: relative;
  padding-bottom: 26.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

@media screen and (max-width: 640px) {
  .ggmap {
    padding-bottom: 55%;
  }
}

.ggmap iframe,
.ggmap object,
.ggmap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 834px) {
  .youtube iframe {
    width: 100%;
  }
}

/********* 2カラム *********/
.works_cont01 {
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1120px) {
  .works_cont01 {
    width: 97%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .works_cont01 {
    width: 100%;
    max-width: 100%;
  }
}

.works_cont01 .left {
  width: 25%;
  height: 600px;
  background: #da5019;
}

.works_cont01 .right {
  width: 72%;
  height: 600px;
  background: #cccccc;
}

/********* Instagram *********/
.event_Instagram01 {
  border: none !important;
}

.event_Instagram01 .sns_list {
  display: flex;
  flex-flow: row;
  border: none;
}

@media screen and (max-width: 640px) {
  .event_Instagram01 .sns_list {
    flex-wrap: wrap;
  }
}

.event_Instagram01 .sns_text {
  display: none;
  width: 0% !important;
}

.event_Instagram01 .sns_photo {
  width: 100% !important;
}

.event_Instagram01 div.sns_list > div {
  display: flex;
  border-bottom: 0px dashed #ccc;
  width: 16.6666666666%;
}

@media screen and (max-width: 640px) {
  .event_Instagram01 div.sns_list > div {
    width: 33.3333333%;
  }
}

.event_Instagram02 .sns_photo {
  width: 40% !important;
}

@media screen and (max-width: 640px) {
  .event_Instagram02 .sns_photo {
    width: 100% !important;
  }
}

.event_Instagram02 .sns_date {
  background: #6dc2eb;
  color: #fff;
  padding: 0.3em 1em;
  color: #fff !important;
  font-size: 1rem;
}

.event_Instagram02 .clearfix a {
  color: #6dc2eb !important;
}

.event_Instagram02 .caption {
  padding: 1em 0;
  font-size: 1rem;
}

.event_Instagram02 div.sns_list > div {
  padding: 40px;
}

@media screen and (max-width: 640px) {
  .event_Instagram02 div.sns_list > div {
    padding: 10px;
  }
}

/********* 404 *********/
.error_txt {
  text-align: center;
}

.error_txt h2 {
  display: inline-block;
  font-size: 2rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .error_txt h2 {
    line-height: 3rem;
  }
}

.error_txt a {
  display: inline-block;
  color: #333 !important;
  border: #333 1px solid;
  border-radius: 500px;
  padding: 1em 2em;
  margin-top: 3em;
}

/********* HOMEのお問い合わせ *********/
.home_contact {
  padding: 100px 0;
  background-size: cover;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .home_contact {
    padding: 40px 0;
  }
}

.home_contact br {
  display: none;
}

.home_contact .inner {
  background: rgba(0, 0, 0, 0.85);
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
  padding: 45px;
  margin: 100px auto;
  color: #fff;
}

@media screen and (max-width: 1120px) {
  .home_contact .inner {
    width: 97%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .home_contact .inner {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .home_contact .inner {
    padding: 30px 10px;
    margin: 40px 0;
  }
}

.home_contact .inner h2 {
  border-bottom: 1px solid #fff;
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 1.71429rem;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding-bottom: 0.5em;
}

@media screen and (max-width: 640px) {
  .home_contact .inner h2 {
    font-size: 1.42857rem;
  }
}

.home_contact .inner .box {
  display: flex;
  align-items: center;
  flex-flow: column;
}

@media screen and (max-width: 640px) {
  .home_contact .inner .box {
    flex-flow: column;
  }
}

.home_contact .inner .tel {
  font-size: 2.57143rem;
  display: inline-block;
  text-align: center;
  font-family: 'Times New Roman';
  color: #fff !important;
  background: url(../images/home/icon_tel02.png) left center no-repeat;
  padding-left: 1.5em;
}

.home_contact .inner .tel a {
  font-size: 2.57143rem;
  display: inline-block;
  text-align: center;
  font-family: 'Times New Roman';
  color: #fff !important;
}

.home_contact .inner .contact {
  display: block;
  text-align: center;
  background: linear-gradient(135deg, #06beb6 0%, #48b1bf 100%);
  width: 47%;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  color: #fff !important;
}

@media screen and (max-width: 640px) {
  .home_contact .inner .contact {
    width: 100%;
  }
}

.contact_tel a {
  color: #333 !important;
  font-size: 2.57143rem;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

/********* 新着情報 *********/
.news_area {
  max-height: 250px;
  overflow-y: auto;
}

.news_area td {
  padding: 10px !important;
  border-bottom: 1px dashed #ccc !important;
}

.news_area td:nth-child(odd) {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .news_area td:nth-child(odd) {
    border-bottom: none !important;
    background: rgba(109, 194, 235, 0.1);
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .news_area td:nth-child(even) {
    padding-top: 0 !important;
    padding-bottom: 30px !important;
  }
}

.news_area dt, .news_area dd {
  background: none !important;
  border: none !important;
}

.news_area dt {
  flex-direction: row !important;
}

.news_area dt > span {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.news_area dl {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

@media screen and (max-width: 640px) {
  .news_area dl {
    display: flex;
    flex-flow: column;
  }
  .news_area dt {
    background: rgba(109, 194, 235, 0.1) !important;
  }
  .news_area dt, .news_area dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
  .news_area dt:nth-child(even), .news_area dd:nth-child(even) {
    padding-bottom: 1em !important;
  }
}

.home_news .blog_list {
  border: none;
}

.home_news .blog_list > div:first-child h3:after {
  content: "\f0da New!!";
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
  font-weight: bold;
  color: #6dc2eb;
}

.home_news .blog_photo {
  width: 0%;
  display: none;
}

.home_news .blog_text {
  width: 100% !important;
}

.home_news .blog_text h3 {
  font-size: 1.14286rem;
  margin-bottom: 0;
  border-bottom: 1px dashed #6dc2eb;
  display: inline-block;
}

.home_news .blog_text h3 a {
  color: #333 !important;
  font-weight: bold;
}

.home_news .detail {
  display: none;
}

.home_news .blog_date {
  font-size: 1.14286rem;
  color: #6dc2eb !important;
  font-weight: bold;
}

/********* コンタクトエリア *********/
.contact_area {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 100px 10px;
  background: url(../images/home/bg_home03.jpg);
  background-size: cover;
}

@media screen and (max-width: 700px) {
  .contact_area {
    padding: 60px 10px;
  }
}

.contact_area .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact_area .h2_box {
  width: 100%;
  margin-bottom: 30px;
}

.contact_area .h2_box br {
  display: none;
}

.contact_area h2 {
  font-size: 2.57143rem;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .contact_area h2 {
    font-size: 2.14286rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .contact_area h2 {
    font-size: 2.14286rem;
  }
}

.contact_area .tel_box, .contact_area .contact_box {
  width: 49%;
  background: #fff;
  padding-top: 30px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) {
  .contact_area .tel_box, .contact_area .contact_box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.contact_area .tel_box td, .contact_area .contact_box td {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: none !important;
}

.contact_area .tel_box tr:first-child td, .contact_area .contact_box tr:first-child td {
  background: #1676bf;
}

.contact_area .tel_box tr:first-child td h3, .contact_area .contact_box tr:first-child td h3 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  text-align: center;
}

.contact_area .tel_box tr:last-child td, .contact_area .contact_box tr:last-child td {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 !important;
}

.contact_area .tel_box tr:last-child td .tel_link, .contact_area .contact_box tr:last-child td .tel_link {
  font-size: 48px;
  color: #333 !important;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 2000px) {
  .contact_area .tel_box tr:last-child td .tel_link, .contact_area .contact_box tr:last-child td .tel_link {
    font-size: 60px;
  }
}

.contact_area .tel_box tr:last-child td .tel_link i, .contact_area .contact_box tr:last-child td .tel_link i {
  font-size: 0.85em;
}

.contact_area .contact_box {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) {
  .contact_area .contact_box {
    width: 100%;
  }
}

.contact_area .contact_box tr:first-child td {
  background: #309aec;
}

.contact_area .contact_box tr:last-child td {
  text-align: center !important;
}

.contact_area .contact_box tr:last-child td br {
  display: none;
}

.contact_area .contact_box a {
  display: block;
  width: 300px;
  height: 100px;
  line-height: 100px;
  background: #eee;
  text-align: center;
  color: #333 !important;
  font-weight: bold;
  font-size: 1.14286rem;
  border: solid 1px #bcc1bd;
  position: relative;
}

.contact_area .contact_box a:before {
  content: "";
  display: block;
  background: url(../images/home/delta01.png);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 3px;
  top: 3px;
}

.contact_area .contact_box a:after {
  content: "";
  display: block;
  background: url(../images/home/delta02.png);
  width: 12px;
  height: 12px;
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.contact_area .contact_box a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_area .contact_box a span:before {
  content: "";
  display: block;
  background: url(../images/common/icon_mail.png);
  height: 28px;
  width: 40px;
  margin-right: 5px;
}

/********* 会社概要 *********/
.company_area table td {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 10px !important;
}

.company_area table td:nth-child(odd) {
  border-bottom: 1px solid #6dc2eb !important;
}

@media screen and (max-width: 640px) {
  .company_area table td:nth-child(odd) {
    background: rgba(109, 194, 235, 0.1);
    width: 100% !important;
  }
}

.company_area dt, .company_area dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 18px;
}

.company_area dt {
  border-bottom: 1px solid #ccc !important;
}

.company_area dd {
  border-bottom: 1px solid #eee !important;
}

@media screen and (max-width: 640px) {
  .company_area dl {
    display: flex;
    flex-flow: column;
  }
  .company_area dt {
    border-bottom: 0 !important;
    background: #f9f9f9 !important;
    font-weight: bold;
  }
  .company_area dt, .company_area dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

#builingual {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 6999999;
  padding: 5px 20px !important;
}

@media screen and (max-width: 640px) {
  #builingual {
    width: 100%;
  }
}

.switch {
  float: right;
  padding-top: 3px !important;
  position: relative;
}

.switch:after {
  content: "OFF";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 8px;
  z-index: 1;
}

.switch:before {
  content: "ON";
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 8px;
  z-index: 1;
}

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}

input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: 0.4s;
}

input.cmn-toggle-round + label:after {
  width: 28px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.4s;
  z-index: 2;
}

input.cmn-toggle-round:checked + label:before {
  background-color: #8ce196;
}

input.cmn-toggle-round:checked + label:after {
  margin-left: 28px;
}

@media only screen and (max-width: 768px) {
  .switch {
    float: right;
    order: 1;
    padding-top: 0px !important;
  }
  #builingual {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.news_area span span:nth-child(2) {
  display: none !important;
}

.trans_01 {
  color: #fff;
}

/********* Other *********/
.b_none td {
  border: none !important;
}

.p_none, .c_none {
  pointer-events: none !important;
}

.inner_out {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

a.tel_link {
  pointer-events: none;
}

@media screen and (max-width: 640px) {
  a.tel_link {
    pointer-events: auto;
  }
}

header#global_header {
  max-width: 100%;
  min-width: auto !important;
}

header#global_header button {
  display: none !important;
}

@media screen and (max-width: 834px) {
  header#global_header button {
    display: block !important;
  }
}

@media screen and (max-width: 834px) {
  #siteID {
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 18px;
    padding-right: .75rem;
    padding-bottom: 18px;
    padding-left: .75rem;
    z-index: 10;
    width: 100%;
    text-align: left;
    background: #0496c5;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  transition: .3s;
  text-align: left;
  background: #0496c5;
}

@media screen and (max-width: 834px) {
  #header {
    text-align: center;
    padding: 1em 2em;
    display: none;
  }
}

.header_container {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .header_container {
    justify-content: center;
    flex-flow: column;
  }
}

.header_container .col_01 {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

nav#nav_global {
  width: 100%;
}

@media screen and (max-width: 834px) {
  nav#nav_global {
    display: none;
  }
}

nav#nav_global ul {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

nav#nav_global ul li {
  border-right: 1px solid #fff;
  flex-grow: 1;
  position: relative;
}

nav#nav_global ul li:last-child {
  border-right: 0;
}

nav#nav_global ul li a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 10px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #fff !important;
}

@media screen and (max-width: 1120px) {
  nav#nav_global ul li a {
    font-size: 10px;
  }
}

nav#nav_global ul li a > span {
  font-size: 1.45em;
  display: block;
}

nav#nav_global ul li a:hover {
  transition: 0.5s;
}

nav#nav_global ul li img {
  max-width: calc(300px / 1);
}

nav#nav_global ul li ul {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99999;
  display: flex;
  flex-flow: column;
  background-color: #6dc2eb;
}

nav#nav_global ul li ul li a {
  display: inline-block;
  padding: 10px 0;
}

nav#nav_global ul li ul li a:hover {
  transition: 0.5s;
}

#mainArea {
  position: relative;
}

#mainArea .swiper-container img {
  width: 100% !important;
  max-width: 100% !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (max-width: 834px) {
  #mainArea .swiper-container img {
    height: auto !important;
  }
}

@media screen and (max-width: 640px) {
  #mainArea .swiper-container img {
    min-height: 250px !important;
    height: 40vh !important;
  }
}

#mainArea .main_txt {
  text-align: left;
  position: absolute;
  z-index: 9;
  top: 60%;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#mainArea .main_txt span {
  display: inline-block;
  font-size: 2.3vw;
  color: #fff;
  background: #0496c5;
  margin-bottom: 0.25em;
  padding: 0.1em 0.5em;
  font-weight: 900;
}

@media screen and (max-width: 640px) {
  #mainArea .main_txt span {
    font-size: 1.6em;
  }
}

/*-----------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------*/
#global_footer {
  background-color: #f2f2f2 !important;
  position: relative;
  min-width: auto !important;
}

#footer {
  background: #6dc2eb;
  padding: 30px 0;
}

#footer .footer_container {
  width: 100%;
}

#footer .footer_container .col_01 {
  text-align: center;
}

@media screen and (max-width: 640px) {
  #footer .footer_container .col_01 {
    margin-bottom: 2em;
    text-align: center;
  }
}

#footer .footer_container .col_01 .h_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  margin-bottom: 20px;
}

#footer .footer_container .col_01 .h_logo img {
  max-width: calc(236px / 1);
}

#footer .footer_container .col_01 .h_logo .header_pt {
  margin-left: 0.25em;
}

.nav_footer {
  display: flex;
  flex-flow: row;
  position: relative;
  justify-content: center;
  text-align: center;
}

.nav_footer li:after {
  content: "｜";
  color: #fff !important;
}

.nav_footer li:last-child:after {
  display: none;
}

.nav_footer li a {
  color: #fff !important;
}

#copyright {
  background: #0496c5;
  padding: 0.5em 0;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 834px) {
  #copyright {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 640px) {
  #copyright {
    padding-bottom: 85px;
  }
}

/*-----------------------------------------------------------------------------------------*/
#pagetop {
  position: fixed;
  right: 2%;
  bottom: 20px;
  z-index: 99999;
}

@media screen and (max-width: 834px) {
  #pagetop {
    bottom: 26px !important;
    right: 3% !important;
  }
}

@media screen and (max-width: 640px) {
  #pagetop {
    bottom: 22px !important;
  }
}

#pagetop a {
  background: #6dc2eb;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #pagetop a {
    height: 80px;
    width: 40px;
  }
}

@media screen and (max-width: 640px) {
  #pagetop a {
    height: 45px;
    width: 25px;
  }
}

#pagetop i {
  color: #fff !important;
}

#contents_wrap {
  padding: 0;
  padding-top: 150px;
}

@media screen and (max-width: 1120px) {
  #contents_wrap {
    padding: 150px 10px 0;
  }
}

@media screen and (max-width: 834px) {
  #contents_wrap {
    padding: 100px 10px 0;
  }
}

@media screen and (max-width: 640px) {
  #contents_wrap {
    padding: 50px 10px 0;
  }
}

.home_sns {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.home_sns .sns_text {
  display: none;
}

.home_sns .sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.home_sns div.sns_list {
  border: none !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  height: 33.3333333vw;
}

.home_sns div.sns_list > div {
  border: none !important;
  width: 16.6666vw;
  height: 16.6666vw;
}

.home_sns div.sns_list > div:nth-child(5) {
  width: 33.3333vw;
  height: 33.3333vw;
}

.h1_txt01 h1 {
  font-size: 2.57143rem;
  color: #6dc2eb;
  font-weight: 900;
}

.h1_txt01 span {
  font-size: 1.42857rem;
  font-weight: bold;
  color: #0496c5;
  display: block;
}

.h2_txt01 h1, .h2_txt01 h2, .h1_txt01 h1, .h1_txt01 h2 {
  font-size: 3vw;
  color: #6dc2eb;
  font-weight: 900;
}

@media screen and (max-width: 834px) {
  .h2_txt01 h1, .h2_txt01 h2, .h1_txt01 h1, .h1_txt01 h2 {
    font-size: 2.57143rem;
  }
}

.h2_txt01 h1 span, .h2_txt01 h2 span, .h1_txt01 h1 span, .h1_txt01 h2 span {
  margin-left: 15px;
  font-size: 0.5em;
  color: #0496c5;
}

.h2_txt02 h2 {
  background: #eee;
  padding: 1.5em 1.5em;
  font-size: 1.14286rem;
  position: relative;
  font-weight: bold;
}

.h2_txt02 h2:before {
  content: "";
  display: block;
  width: 5px;
  height: 15px;
  border-radius: 500px;
  background: #6dc2eb;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.pankuzu {
  text-align: center !important;
}

.pankuzu a {
  font-size: 13px;
}

/*-------------------- HOME --------------------*/
.home_01 {
  font-size: 1.28571rem;
  line-height: 2;
}

@media screen and (max-width: 640px) {
  .home_01 {
    font-size: 13px;
  }
}

.home_01 h2 {
  font-size: 40px;
  font-weight: 900;
  color: #6dc2eb;
}

.home_03_R .inner {
  flex-direction: row-reverse;
}

.home_03, .home_03_R {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .home_03, .home_03_R {
    flex-wrap: wrap;
  }
}

.home_03 .inner, .home_03_R .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .home_03 .inner, .home_03_R .inner {
    flex-wrap: wrap;
  }
}

.home_03 .inner .flxL, .home_03_R .inner .flxL {
  width: 45%;
}

@media screen and (max-width: 640px) {
  .home_03 .inner .flxL, .home_03_R .inner .flxL {
    width: 100%;
  }
}

.home_03 .inner .flxR, .home_03_R .inner .flxR {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .home_03 .inner .flxR, .home_03_R .inner .flxR {
    width: 100%;
    margin-top: 30px;
  }
}

.home_03 .box:first-child, .home_03_R .box:first-child {
  width: 45%;
}

@media screen and (max-width: 640px) {
  .home_03 .box:first-child, .home_03_R .box:first-child {
    width: 100%;
  }
}

.home_03 .box:last-child, .home_03_R .box:last-child {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .home_03 .box:last-child, .home_03_R .box:last-child {
    width: 100%;
    margin-top: 30px;
  }
}

.home_03 a, .home_03_R a {
  display: inline-block;
  width: 250px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff !important;
  background: #6dc2eb;
  position: relative;
}

@media screen and (max-width: 640px) {
  .home_03 a, .home_03_R a {
    margin-top: 0.75em;
  }
}

.home_03 a:after, .home_03_R a:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.fxdrr {
  flex-direction: row-reverse;
}

.home_03_R {
  flex-direction: row-reverse !important;
}

.home_02 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  position: relative;
}

.home_02:before {
  content: "";
  display: block;
  width: 100%;
  height: 700px;
  background: url(../images/common/bg_1.jpg);
  background-size: cover;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: -1;
}

.home_02 h2 span {
  color: #fff !important;
}

.home_02 .container {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .home_02 .container {
    flex-wrap: wrap !important;
  }
}

.home_02 .container .box {
  margin: 0 1.5%;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center !important;
  font-size: 1rem;
}

@media screen and (max-width: 834px) {
  .home_02 .container .box {
    font-size: 1.42857rem;
  }
}

@media screen and (max-width: 640px) {
  .home_02 .container .box {
    width: 100% !important;
    margin-bottom: 40px;
  }
}

.home_02 .container .box h3 {
  font-size: 1.42857rem;
  font-weight: 900;
  margin: 1.5em 0;
}

@media screen and (max-width: 640px) {
  .home_02 .container .box h3 {
    font-size: 1.71429rem;
    margin-bottom: 0.5em;
  }
}

.home_02 .container .box .katagaki {
  display: inline-block;
  border: 1px solid #333;
  padding: 0.5em;
  min-width: 200px;
  margin-right: 1em;
}

@media screen and (max-width: 834px) {
  .home_02 .container .box .katagaki {
    display: block;
    font-size: 0.65em;
    padding: 0.2em;
  }
}

@media screen and (max-width: 640px) {
  .home_02 .container .box .katagaki {
    margin: 0;
  }
}

@media screen and (max-width: 834px) {
  .home_02 .container article h3 {
    padding: 0 1.5em;
  }
  .home_02 .container article > div {
    padding: 0 1em;
  }
}

.home_02 a.btn {
  display: inline-block;
  width: 150px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff !important;
  background: #6dc2eb;
  position: relative;
  float: right;
}

@media screen and (max-width: 834px) {
  .home_02 a.btn {
    float: none;
    font-size: 1rem;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 640px) {
  .home_02 a.btn {
    font-size: 1.28571rem;
    width: 200px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 2.5em;
  }
}

.home_02 a.btn:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.home_btn01 a {
  display: inline-block;
  width: 250px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff !important;
  background: #6dc2eb;
  position: relative;
}

.home_btn01 a:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.home_sns {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  height: 33.3333333vw;
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.home_sns .box {
  border: none !important;
  width: 16.6666vw;
  height: 16.6666vw;
}

.home_sns .box:nth-child(5) {
  width: 33.3333vw;
  height: 33.3333vw;
}

.home_sns .box a {
  display: block;
  position: relative;
}

.home_sns .box a img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.home_sns .box a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

@media screen and (max-width: 640px) {
  .home_sns .box a:before {
    padding-top: 50%;
  }
}

.home_04 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/common/bg_2.jpg);
  background-size: cover;
  background-position: center center;
  padding: 100px 10px;
  color: #111;
}

@media screen and (max-width: 640px) {
  .home_04 {
    padding: 40px 10px;
  }
}

.home_04 article {
  display: block !important;
  width: 100% !important;
}

.home_04 h2 {
  text-align: center !important;
  font-size: 3.4vw;
  color: #6dc2eb;
  font-weight: 900;
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .home_04 h2 {
    font-size: 2.57143rem;
    margin-bottom: 15px;
  }
}

.home_04 h2 span {
  font-size: 0.5em;
  color: #0496c5;
  display: block;
}

.home_04 div {
  text-align: center !important;
  margin: 0 auto !important;
}

.home_04 ul {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.home_04 ul li {
  width: 30%;
  margin: 0 2%;
}

@media screen and (max-width: 640px) {
  .home_04 ul li {
    width: 50%;
    margin: 0 auto !important;
  }
}

.home_04 ul a {
  display: block;
  height: 60px;
  line-height: 60px;
  color: #fff !important;
  position: relative;
  background: #0496c5;
  text-align: center;
  opacity: 1;
}

.home_04 ul a:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.home_04 ul a:hover {
  background: #0782aa;
}

@media screen and (max-width: 640px) {
  .recruit_01 dl:first-child {
    display: none;
  }
}

.recruit_01 dl:first-child dt, .recruit_01 dl:first-child dd {
  background: #eee !important;
  color: #6dc2eb;
  padding: 0.5em !important;
  border-bottom: none !important;
}

@media screen and (max-width: 640px) {
  .recruit_01 dl {
    flex-direction: column;
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
  }
}

.recruit_01 dt, .recruit_01 dd {
  border: none !important;
  background: #fff !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 1rem;
  padding: 2em 0.5em !important;
}

@media screen and (max-width: 640px) {
  .recruit_01 dt, .recruit_01 dd {
    padding: 0.5em 1em !important;
    border-bottom: 0 !important;
    background: transparent !important;
  }
}

.recruit_01 dt {
  justify-content: flex-start !important;
  width: 30% !important;
}

@media screen and (max-width: 640px) {
  .recruit_01 dt {
    width: 100% !important;
    display: flex;
    align-items: center !important;
  }
}

.recruit_01 dt > span {
  width: 80%;
  background: #6dc2eb;
  text-align: center;
  display: block;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .recruit_01 dt > span {
    font-size: 16px;
  }
}

.recruit_01 dd {
  width: 70% !important;
}

@media screen and (max-width: 640px) {
  .recruit_01 dd {
    width: 100% !important;
  }
}

.recruit_01 dd a.btn_r {
  display: block;
  background: #6dc2eb;
  width: 250px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  color: #fff !important;
  position: relative;
}

@media screen and (max-width: 640px) {
  .recruit_01 dd a.btn_r {
    margin: 0 auto 0px;
  }
}

.recruit_01 dd a.btn_r:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.h2_txt03 h2 {
  display: block;
  border: 1px solid #6dc2eb;
  width: 100%;
  padding: 0.5em 2em;
  font-size: 1.71429rem;
  color: #6dc2eb;
  font-weight: bold;
  position: relative;
}

@media screen and (max-width: 640px) {
  .h2_txt03 h2 {
    font-size: 1.28571rem;
    padding-left: 2.5em;
  }
}

.h2_txt03 h2:after {
  content: "\f4a2";
  font-family: "font awesome 5 pro";
  color: #6dc2eb;
  display: block;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.member_01 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f2f2f2;
  padding: 60px 10px;
}

@media screen and (max-width: 640px) {
  .member_01 {
    padding: 30px 10px;
  }
}

.member_01:nth-child(even) {
  background: #fafafa;
}

.member_01:nth-child(even) .inner {
  flex-direction: row-reverse;
}

.member_01 .inner {
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1120px) {
  .member_01 .inner {
    width: 97%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .member_01 .inner {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .member_01 .inner {
    flex-wrap: wrap;
  }
}

.member_01 .img {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .member_01 .img {
    width: 100%;
    margin-bottom: 20px;
  }
  .member_01 .img img {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.member_01 .box {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .member_01 .box {
    width: 100%;
  }
}

.member_01 .box td {
  padding: 0 !important;
  border: none;
}

.member_01 .katagaki > div {
  font-size: 1.14286rem;
  color: rgba(109, 194, 235, 0.75);
  margin-bottom: 0.5em;
}

@media screen and (max-width: 640px) {
  .member_01 .katagaki > div {
    font-size: 1rem;
  }
}

.member_01 .name {
  font-size: 2.14286rem;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #6dc2eb;
}

@media screen and (max-width: 834px) {
  .member_01 .name {
    font-size: 1.42857rem;
  }
}

.member_01 .txt > div {
  background: rgba(109, 194, 235, 0.05);
  font-size: 13px;
  padding: 25px !important;
  margin: 2em 0;
}

@media screen and (max-width: 640px) {
  .member_01 .txt > div {
    padding: 15px !important;
  }
}

.member_01 .btn a {
  display: inline-block;
  border: 1px solid #6dc2eb;
  color: rgba(109, 194, 235, 0.6) !important;
  padding: 0.5em 3em;
  background: #fff;
  border-radius: 500px;
  transition: 1s;
  background: #6dc2eb;
  color: #fff !important;
  transition: 1s;
  opacity: 1 !important;
}

.member_01 .btn a:hover {
  background: #fff;
  color: #6dc2eb !important;
  transition: 1s;
}

.member_02 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  justify-content: center;
  align-items: center;
}

.member_02:nth-child(odd) {
  flex-direction: row-reverse !important;
}

@media screen and (max-width: 640px) {
  .member_02 {
    flex-wrap: wrap;
  }
}

.member_02 .box {
  width: 45%;
  margin: 0 2%;
  overflow: visible !important;
  line-height: 1.8;
}

@media screen and (max-width: 640px) {
  .member_02 .box {
    width: 100%;
    margin-bottom: 25px;
  }
}

.member_02 .box h3 {
  font-size: 1.71429rem;
  font-weight: bold;
  color: #6dc2eb;
  margin-bottom: 1em;
  position: relative;
  padding-left: 1.25em;
}

@media screen and (max-width: 640px) {
  .member_02 .box h3 {
    margin-bottom: 0.5em;
  }
}

.member_02 .box h3:before {
  content: "\f064";
  font-family: "font awesome 5 pro";
  display: inline-block;
  margin-right: 0.3em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.member_02 .box img {
  width: 100% !important;
  display: block;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 640px) {
  .member_02 .box img {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.entry_container {
  margin-top: 150px;
  text-align: center !important;
}

@media screen and (max-width: 640px) {
  .entry_container {
    margin-top: 80px;
  }
}

.entry_container .home_04 a {
  margin-top: 40px;
}

@media screen and (max-width: 640px) {
  .entry_container .home_04 a {
    margin-top: 15px;
  }
}

@media screen and (max-width: 640px) {
  .recruit_title ul + a + a {
    right: 0;
  }
}

@media screen and (max-width: 640px) {
  .recruit_title ul + a {
    right: 110px;
  }
}

@media screen and (max-width: 640px) {
  .recruit {
    padding: 15px !important;
    padding-top: 50px !important;
    margin: 15px 0 15px !important;
  }
}

.recruit h1 {
  color: #6dc2eb;
  background: #f9f9f9;
  padding: 1.5em 2em;
  position: relative;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .recruit h1 {
    padding: 0.5em 1em;
    font-size: 1.28571rem;
  }
}

.recruit h1:before {
  content: "";
  display: block;
  width: 5px;
  height: 15px;
  border-radius: 500px;
  background: #6dc2eb;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.recruit .recruit_title > a {
  color: #fff !important;
  background: rgba(109, 194, 235, 0.8);
  height: 60px;
  font-size: 1rem;
  letter-spacing: 0.1em;
}

.recruit .recruit_title > a br {
  display: none;
}

.recruit .recruit_title ul li {
  background: #6dc2eb;
  color: #fff;
  border: none;
  padding: 0.2em 0.5em;
  font-size: 1rem;
}

@media screen and (max-width: 640px) {
  .recruit .recruit_title ul li {
    margin-top: 0.5em;
    margin-right: 3%;
    width: 30%;
    text-align: center;
  }
  .recruit .recruit_title ul li:nth-child(3n) {
    margin-right: 0;
  }
}

.recruit .recruit_gists {
  background: #f3f3f3;
  padding: 40px;
  margin-bottom: 25px;
}

@media screen and (max-width: 640px) {
  .recruit .recruit_gists {
    padding: 15px;
  }
}

.recruit .recruit_gists h3 {
  font-size: 1.28571rem;
  font-weight: bold;
  color: #6dc2eb;
  margin-bottom: 0.5em;
}

.recruit .recruit_gists h3:before {
  content: "\f064";
  font-family: "font awesome 5 pro";
  display: inline-block;
  margin-right: 0.3em;
}

.recruit .recruit_link a {
  display: block;
  width: 250px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff !important;
  background: #6dc2eb;
  position: relative;
  margin: 0 auto;
}

.recruit .recruit_link a:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.recruit .recruit_detail dl {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 2em 1em !important;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .recruit .recruit_detail dl {
    padding: 2em 0 !important;
  }
}

@media screen and (max-width: 834px) {
  .recruit .recruit_detail dt, .recruit .recruit_detail dd {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.recruit .recruit_form dt {
  width: 30% !important;
  max-width: 30% !important;
}

@media screen and (max-width: 640px) {
  .recruit .recruit_form dt {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.recruit .recruit_form dd {
  width: 70% !important;
  max-width: 70% !important;
}

@media screen and (max-width: 640px) {
  .recruit .recruit_form dd {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.position {
  text-align: center;
}

.position > div {
  display: inline-block;
  background: #eee;
  padding: 0.3em 3em;
  border-radius: 5px;
  font-weight: bold;
}

div.recruit_index article.recruit > div:nth-child(1) {
  width: 300px;
}

@media screen and (max-width: 834px) {
  div.recruit_index article.recruit > div:nth-child(1) {
    width: 100%;
  }
}

div.recruit_index article.recruit > div:nth-child(1) img, .recruit_main_image img {
  width: 100% !important;
}

.recruit_index h1 {
  background: #f1f1f1;
  color: #333 !important;
  padding: 1em;
  border-radius: 2px;
  position: relative;
  font-weight: bold;
}

.recruit_index h1:before {
  content: "";
  display: block;
  width: 5px;
  height: 15px;
  border-radius: 500px;
  background: #0496c5;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 640px) {
  .recruit_index h1 {
    margin-left: 0 !important;
  }
}

.recruit_index h2 a {
  color: #333 !important;
  font-weight: bold;
}

.recruit_index .recruit_main_text {
  background: #f3f3f3;
  padding: 20px;
}

.recruit_index .recruit_nav a {
  background: #0496c5;
  color: #fff !important;
  display: block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
}

@media screen and (max-width: 640px) {
  .recruit_index .recruit_nav a {
    width: 100%;
  }
}

.about_01 {
  justify-content: space-between;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1.7543% !important;
}

.about_01 .box {
  width: 23.2456%;
  margin-left: 1.7543%;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  background: #f7f7f7;
}

.about_01 .box h3 {
  color: #004898;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #004898;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.about_01 .box div {
  padding: 15px;
  background: #fff;
}

.newswrap {
  background: #f7f7f7;
  border: 3px solid #ccc;
  padding: 50px;
  margin-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .newswrap {
    margin-bottom: 50px;
  }
}

.news {
  z-index: 1;
  position: relative;
  height: 450px;
  overflow-y: scroll;
  padding-right: 10px;
}

@media screen and (max-width: 834px) {
  .news {
    height: 350px;
  }
}

.news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.news dl dt, .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

/*!
 * jquery-drawer v3.2.2
 * Flexible drawer menu using jQuery, iScroll and CSS.
 * http://git.blivesta.com/drawer
 * License : MIT
 * Author : blivesta <design@blivesta.com> (http://blivesta.com/)
 */
.drawer-nav {
  display: none;
}

@media screen and (max-width: 834px) {
  /*!------------------------------------*\
    Base
\*!------------------------------------*/
  .drawer-nav {
    position: fixed;
    z-index: 9999999999999999;
    top: 0;
    overflow: hidden;
    width: 16.25rem;
    height: 100%;
    color: #222;
    background-color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drawer-menu {
    width: 75% !important;
    margin: 0 !important;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: left;
  }
  .drawer-menu li {
    text-align: left !important;
    display: block;
    width: 100%;
  }
  .drawer-menu li a {
    font-size: 12px;
    font-weight: 400;
    font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    color: #333 !important;
    line-height: 3;
    display: block;
    border-bottom: 1px solid #49b3e6;
  }
  .drawer-menu li a span {
    font-size: 1.35em;
    display: inline-block;
    margin-right: 0.5em;
  }
  .drawer-menu li ul li a {
    display: inline-block;
    background: #2aa6e2;
    padding: 0.2em 0.5em !important;
  }
  .drawer-menu li ul li:nth-child(even) a {
    background: #37ace4;
  }
  .drawer-hamburger {
    position: relative;
  }
  .drawer-hamburger:after {
    content: "menu";
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    margin: 0 auto;
    color: #fff;
  }
  .drawer-open {
    overflow: hidden !important;
  }
  .drawer-brand {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 3.75rem;
    display: block;
    padding-right: .75rem;
    padding-left: .75rem;
    text-decoration: none;
    color: #222;
  }
  .drawer-menu-item {
    font-size: 1rem;
    display: block;
    padding: .75rem;
    text-decoration: none;
    color: #222;
  }
  .drawer-menu-item:hover {
    text-decoration: underline;
    color: #555;
    background-color: transparent;
  }
  /*! overlay */
  .drawer-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .drawer-open .drawer-overlay {
    display: block;
  }
  /*!------------------------------------*\
    Top
\*!------------------------------------*/
  .drawer--top .drawer-nav {
    top: -100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 100%;
    -webkit-transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .drawer--top.drawer-open .drawer-nav {
    top: 0;
  }
  .drawer--top .drawer-hamburger,
  .drawer--top.drawer-open .drawer-hamburger {
    right: 0;
  }
  /*!------------------------------------*\
    Left
\*!------------------------------------*/
  .drawer--left .drawer-nav {
    left: -16.25rem;
    -webkit-transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .drawer--left.drawer-open .drawer-nav,
  .drawer--left .drawer-hamburger,
  .drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
    left: 0;
  }
  .drawer--left.drawer-open .drawer-hamburger {
    left: 16.25rem;
  }
  /*!------------------------------------*\
    Right
\*!------------------------------------*/
  .drawer--right .drawer-nav {
    right: -16.25rem;
    -webkit-transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .drawer--right.drawer-open .drawer-nav,
  .drawer--right .drawer-hamburger,
  .drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
    right: 0;
  }
  .drawer--right.drawer-open .drawer-hamburger {
    right: 16.25rem;
  }
  /*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
  .drawer-hamburger {
    position: fixed;
    z-index: 999999999999999999999999;
    top: 0;
    display: block;
    box-sizing: content-box;
    width: 2rem;
    padding: 0;
    padding-top: 15px;
    padding-right: .75rem;
    padding-bottom: 30px;
    padding-left: .75rem;
    -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border: 0;
    outline: 0;
    background-color: transparent;
  }
  .drawer-hamburger:hover {
    cursor: pointer;
    background-color: transparent;
  }
  .drawer-hamburger-icon {
    position: relative;
    display: block;
    margin-top: 10px;
  }
  .drawer-hamburger-icon,
  .drawer-hamburger-icon:before,
  .drawer-hamburger-icon:after {
    width: 100%;
    height: 2px;
    -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    background-color: #fff;
  }
  .drawer-hamburger-icon:before,
  .drawer-hamburger-icon:after {
    position: absolute;
    top: -10px;
    left: 0;
    content: ' ';
  }
  .drawer-hamburger-icon:after {
    top: 10px;
  }
  .drawer-open .drawer-hamburger-icon {
    background-color: transparent;
  }
  .drawer-open .drawer-hamburger-icon:before,
  .drawer-open .drawer-hamburger-icon:after {
    top: 0;
  }
  .drawer-open .drawer-hamburger-icon:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .drawer-open .drawer-hamburger-icon:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  /*!------------------------------------*\
    accessibility
\*!------------------------------------*/
  /*!
 * Only display content to screen readers
 * See: http://a11yproject.com/posts/how-to-hide-content
 */
  .sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  /*!
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * Credit: HTML5 Boilerplate
 */
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
  }
  /*!------------------------------------*\
    Sidebar
\*!------------------------------------*/
  .drawer--sidebar {
    background-color: #fff;
  }
  .drawer--sidebar .drawer-contents {
    background-color: #fff;
  }
}

@media screen and (max-width: 834px) and (min-width: 64em) {
  .drawer--sidebar .drawer-hamburger {
    display: none;
    visibility: hidden;
  }
  .drawer--sidebar .drawer-nav {
    display: block;
    -webkit-transform: none;
    transform: none;
    position: fixed;
    width: 12.5rem;
    height: 100%;
  }
  /*! Left */
  .drawer--sidebar.drawer--left .drawer-nav {
    left: 0;
    border-right: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 12.5rem;
  }
  /*! Right */
  .drawer--sidebar.drawer--right .drawer-nav {
    right: 0;
    border-left: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 12.5rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 48rem;
  }
}

@media screen and (max-width: 834px) and (min-width: 75em) {
  .drawer--sidebar .drawer-nav {
    width: 16.25rem;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 16.25rem;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 16.25rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 60rem;
  }
}

@media screen and (max-width: 834px) {
  /*!------------------------------------*\
    Navbar
\*!------------------------------------*/
  .drawer--navbarTopGutter {
    padding-top: 3.75rem;
  }
  .drawer-navbar .drawer-navbar-header {
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }
  .drawer-navbar {
    z-index: 102;
    top: 0;
    width: 100%;
  }
  /*! .drawer-navbar modifier */
  .drawer-navbar--fixed {
    position: fixed;
  }
  .drawer-navbar-header {
    position: relative;
    z-index: 102;
    box-sizing: border-box;
    width: 100%;
    height: 3.75rem;
    padding: 0 .75rem;
    text-align: center;
  }
  .drawer-navbar .drawer-brand {
    line-height: 3.75rem;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    text-decoration: none;
  }
  .drawer-navbar .drawer-brand:hover {
    background-color: transparent;
  }
  .drawer-navbar .drawer-nav {
    padding-top: 3.75rem;
  }
  .drawer-navbar .drawer-menu {
    padding-bottom: 7.5rem;
  }
}

@media screen and (max-width: 834px) and (min-width: 64em) {
  .drawer-navbar {
    height: 3.75rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }
  .drawer-navbar .drawer-navbar-header {
    position: relative;
    display: block;
    float: left;
    width: auto;
    padding: 0;
    border: 0;
  }
  .drawer-navbar .drawer-menu--right {
    float: right;
  }
  .drawer-navbar .drawer-menu li {
    float: left;
  }
  .drawer-navbar .drawer-menu-item {
    line-height: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .drawer-navbar .drawer-hamburger {
    display: none;
  }
  .drawer-navbar .drawer-nav {
    position: relative;
    left: 0;
    overflow: visible;
    width: auto;
    height: 3.75rem;
    padding-top: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .drawer-navbar .drawer-menu {
    padding: 0;
  }
  /*! dropdown */
  .drawer-navbar .drawer-dropdown-menu {
    position: absolute;
    width: 16.25rem;
    border: 1px solid #ddd;
  }
  .drawer-navbar .drawer-dropdown-menu-item {
    padding-left: .75rem;
  }
}

@media screen and (max-width: 834px) {
  /*!------------------------------------*\
    Dropdown
\*!------------------------------------*/
  .drawer-dropdown-menu {
    display: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
  .drawer-dropdown-menu > li {
    width: 100%;
    list-style: none;
  }
  .drawer-dropdown-menu-item {
    line-height: 3.75rem;
    display: block;
    padding: 0;
    padding-right: .75rem;
    padding-left: 1.5rem;
    text-decoration: none;
    color: #222;
  }
  .drawer-dropdown-menu-item:hover {
    text-decoration: underline;
    color: #555;
    background-color: transparent;
  }
  /*! open */
  .drawer-dropdown.open > .drawer-dropdown-menu {
    display: block;
  }
  /*! drawer-caret */
  .drawer-dropdown .drawer-caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    -webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
    transition: opacity .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, opacity .2s ease;
    transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  /*! open */
  .drawer-dropdown.open .drawer-caret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  /*!------------------------------------*\
    Container
\*!------------------------------------*/
  .drawer-container {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 834px) and (min-width: 64em) {
  .drawer-container {
    max-width: 60rem;
  }
}

@media screen and (max-width: 834px) and (min-width: 75em) {
  .drawer-container {
    max-width: 70rem;
  }
}
